.header {
    padding: 0.3rem;
    border-bottom: 1px solid #dfdfdf;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 5%), 0 0.3px 0.9px 0 rgb(0 0 0 / 5%);
}

.headerCell {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vip {
    background-color: blueviolet;
    color: white;
    border-radius: 3px;
    padding: 3px;
}

.totalAssets {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.persona {
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}