.onlineMembers {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onlineMembersItem {
    padding: 0.8rem;
}

.onlineMembersItem:hover {
    background-color: aliceblue;
}

.onlineMembersItem ul {
    padding: 0.2rem;
    padding-left: 1rem;
    margin: 0rem;
    list-style-type: square;
}

.onlineMembersItem li {
    padding-left: 0rem;
    margin: 0;
}

.running {
    padding: 0.2rem;
    border: 1px solid rgb(248, 255, 240);
    background-color: rgb(248, 255, 240);
}

.idle {
    padding: 0.2rem;
    border: 1px solid rgb(255, 244, 240);
    background-color: rgb(255, 244, 240);
}

.active {
    border-left: 2px solid rgb(245, 93, 33);
    background-color: rgb(255, 249, 247);
}
